import Navbar from '../components/navbar';
import Footer from '../components/footer';

function Home() {
    return (
        <>
            <Navbar></Navbar>
            <div className='gradient'>
                <main>
                    <div className='card landing'>
                        <div className='left'>
                            {/* <img src='/img/render.png'></img> */}

                            <img src='/img/mockup.png' alt='Zdjęcie z telefonem przedstawiającym aplikacje Termin Prawka'></img>
                        </div>
                        <div className='right'>
                            <h1>Termin Prawka</h1>
                            <p>Otrzymuj powiadomienia o nowych wolnych terminach na egzamin praktyczny lub teoretyczny prawa jazdy szybciej niż inni i to <i>CAŁKOWICIE ZA DARMO!</i></p>
                            <div className='storeBadges'>
                                <a href='https://apps.apple.com/pl/app/testy-prawo-jazdy-2025-pjazdy/id6740403903?platform=iphone' target='_blank' rel='noreferrer'>
                                    <img src='/img/badges/app_store.png' alt='logo appstore'></img>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=com.sobgog.testy_na_prawo_jazdy_pjazdy' target='_blank' rel='noreferrer'>
                                    <img src='/img/badges/play_store.png' alt='logo sklepu play'></img>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className='card featureCard'>
                        <div className='left'>
                            <h1>Śledź za darmo termin</h1>
                            <p>Dzięki aplikacji dostaniesz powiadomienie o nowym wolnym terminie w WORD na egzamin praktyczny lub teoretyczny. Aktualizujemy dostępne terminy maksymalnie co 5 minut i to za darmo dla każdego użytkownika - nie musisz płacić nam żadnej subskrypcji.</p>
                        </div>
                        <div className='right'>
                            <img src='/img/mockup_sledzenie.png' alt='Zdjęcie z telefonem przedstawiającym aplikacje Termin Prawka'></img>
                        </div>
                    </div>
                    <Footer></Footer>
                </main>

            </div>
        </>
    );
}

export default Home;
